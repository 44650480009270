$default-text: #505050;
$light-text: #818181;
$default-background: #FAF8F4;

$primary-background: #FFED00;
$primary-text: #968C00;
$success-background: #99FF74;
$success-text: #289526;
$info-background: #74E4FD;
$info-text: #dadada;
$warning-background: #FFC107;
$warning-text: #9A7407;
$danger-background: #FF7474;
$danger-text: #952626;
$disabled-background: #ADADAD;
$disabled-text: #575757;
