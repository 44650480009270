@import '../../styles/variables/colors.scss';

h1 {
  font-family: "roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 32px;
  margin: 60px 0;
}

h2 {
  font-family: "roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
}

.error-feedback {
  color: $danger-text;
  font-size: 14px;
}
