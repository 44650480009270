/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "styles/variables/colors.scss";
@import "styles/content/typography.scss";
@import "styles/components/grid.scss";
@import "styles/components/buttons.scss";

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: "roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: $default-text;
  background-color: $default-background;
}

.page {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  // overflow: hidden;
  min-height: 600px;
}

.pagination {
  .page-item {
    .page-link {
      color: black;

      &:focus {
        box-shadow: none;
      }
    }

    &.active {
      .page-link {
        z-index: 2;
        background-color: $primary-background;
        border-color: #dee2e6;
      }
    }
  }
}

.btn-invisible {
  border: 0;
  background: none;
  padding: 3px 6px;
}

textarea {
  min-height: 100px ;
}

.tooltip {
  z-index: 9999;
}

ngb-typeahead-window {
  width: 100%;
}

.input-invalid-message {
  color: $danger-text;
  font-size: 11px;
  margin-top: 4px;
}

input.ng-invalid,
select.ng-invalid,
textarea.ng-invalid {
  border-color: $danger-text;
}

.papercard {
  padding: 16px;
  box-shadow: 0px 1px 8px #00000029;
  border-radius: 10px;
  background-color: #fff;
}
